:local .btn-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  
  &:hover {
    background-color: #5b5b5b;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

  img {
    height: auto;
    width: 1rem;
  }
}